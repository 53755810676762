<template>
    <div class="todo-panel todo-pannel-adjust">
        <!-- todo-pannel-adjust 用于调整样式的类 -->
        <div class="white-bg">
            <div class="invit-tit">
                <h3 class="tit">
                    <span class="line">发送邀请</span>
                </h3>
            </div>
            <div class="twait-box">
                <div class="twait-nath1">
                    <div class="twait-bd invit-bd">
                        <div class="amount-wraper">
                            <div class="amount-left">
                                <div class="amount-inner">
                                    <h4 class="task-name">项目信息</h4>
                                    <ul class="tinfor-list">
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目名称：</span>
                                            <p class="todo-tit-txt">{{taskInfo.task_name}}</p>
                                            <div class="btns-wraper">
                                                <el-button
                                                    size="mini"
                                                    @click="goTaskInfo(taskInfo.uk)"
                                                >查看详情</el-button>
                                                <el-button
                                                    size="mini"
                                                    @click="handleClickEndtask = true"
                                                >结束项目</el-button>
                                            </div>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目编号：</span>
                                            <p class="todo-tit-txt">PG52145875214</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目模式：</span>
                                            <p class="todo-tit-txt">{{taskInfo.status_name}}</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">履约保证金情况：</span>
                                            <p class="todo-tit-txt">{{taskInfo.damages_name}}</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">履约保证金金额：</span>
                                            <p class="todo-tit-txt">-</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="amount-right">
                                <div class="amount-inner">
                                    <h4 class="task-name">极客信息</h4>
                                    <ul class="tinfor-list">
                                        <li class="tinfor-item tinfor-item1">
                                            <div class="btns-wraper">
                                                <el-button
                                                    size="mini"
                                                    @click="gotoGeekDetail(taskInfo._geek.user_id)"
                                                >极客详情</el-button>
                                                <el-button
                                                    size="mini"
                                                    @click="send(taskInfo._geek.user_id)"
                                                >发送消息</el-button>
                                            </div>
                                            <span class="pic-box">
                                                <img :src="websiteConfigs.sourceUrl + avatar" />
                                            </span>
                                            <div class="tinfor-cot">
                                                <h4>{{taskInfo._geek?taskInfo._geek.name:''}}</h4>
                                                <p>{{taskInfo._geek?taskInfo._geek.areas:''}}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <span class="lab-name">身份类型：</span>
                                            <p class="todo-tit-txt">公司工程极客</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">信用评分：</span>
                                            <p
                                                class="todo-tit-txt"
                                            >{{taskInfo._geek?taskInfo._geek.credence:0}}分</p>
                                        </li>
                                        <li class="tinfor-item">
                                            <span class="lab-name">项目报价：</span>
                                            <p
                                                class="todo-tit-txt"
                                            >￥{{taskInfo._enroll?taskInfo._enroll.quoted_price:0}}元</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="setrem-wrap">
                            <div class="grid-content host-tip">
                                <p class="host-tip-txt">
                                    <strong>设置酬金</strong>
                                </p>
                            </div>
                            <div class="setrem-bd">
                                <div class="setrem">
                                    <el-form ref="formRules" :rules="formRules" :model="form">
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-xuanzeriqi"></i>项目工期
                                                <span class="jk-icon-wenhao"></span>
                                            </p>
                                        </el-form-item>
                                        <el-form-item label="项目工期">
                                            <el-date-picker
                                                v-model="form.work_time"
                                                type="daterange"
                                                range-separator="至"
                                                :start-placeholder="start_placeholder"
                                                :end-placeholder="end_placeholder"
                                                format="yyyy 年 MM 月 dd 日"
                                                value-format="yyyy-MM-dd"
                                                :picker-options="pickerOptions"
                                            ></el-date-picker>
                                        </el-form-item>
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-xuanzeriqi"></i>成果最终交付时间
                                                <span class="jk-icon-wenhao"></span>
                                            </p>
                                        </el-form-item>
                                        <el-form-item label="成果最终交付时间为" prop="data">
                                            <el-date-picker
                                                v-model="form.data"
                                                type="date"
                                                format="yyyy 年 MM 月 dd 日"
                                                value-format="yyyy-MM-dd"
                                                placeholder="选择日期"
                                                :picker-options="pickerOptions"
                                            ></el-date-picker>
                                        </el-form-item>

                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-renwuchoujinzhifufangshi"></i>项目酬金及支付方式
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>
                                        <el-form-item
                                            label="项目酬金总额"
                                            class="ipt-mony"
                                            prop="allmoney"
                                            :rules="[
                                  { required: true, message: '项目酬金总额不能为空'}
                                ]"
                                            v-if="taskInfo.task_type && taskInfo.task_type == 1"
                                        >
                                            <el-input
                                                placeholder="请填写"
                                                v-model="form.allmoney"
                                                @change="changePayMoney"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元</span>
                                        </el-form-item>
                                        <el-form-item
                                            label="项目酬金"
                                            class="ipt-mony"
                                            prop="day_money"
                                            :rules="[
                                  { required: true, message: '项目酬金不能为空'},
                                  { type: 'number', message: '项目酬金必须为数字'}
                                ]"
                                            v-if="taskInfo.task_type && taskInfo.task_type == 3"
                                        >
                                            <el-input
                                                placeholder="请填写"
                                                v-model.number="form.day_money"
                                                @change="changePayMoney"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元/天</span>
                                        </el-form-item>
                                        <el-form-item
                                            label="项目工期"
                                            class="ipt-mony"
                                            prop="task_day"
                                            :rules="[
                                  { required: true, message: '项目工期不能为空'},
                                  { type: 'number', message: '项目工期必须为数字'}
                                ]"
                                            v-if="taskInfo.task_type && taskInfo.task_type == 3"
                                        >
                                            <el-input
                                                placeholder="请填写"
                                                v-model.number="form.task_day"
                                                @change="changePayMoney"
                                            >
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>天</span>
                                        </el-form-item>
                                        <el-form-item>
                                            <p class="v-big-tit">
                                                <i class="jk-icon-dianzihetong"></i>是否签署电子合同
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-radio-group v-model="form.contract">
                                                <el-radio-button label="1">不签署</el-radio-button>
                                                <el-radio-button label="2">签署</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item v-if="form.contract == 2">
                                            <p class="v-big-tit">
                                                <i class="jk-icon-daibanlvyuejin"></i>是否需要对方托管履约保证金
                                                <span class="jk-icon-yiwen"></span>
                                            </p>
                                        </el-form-item>
                                        <el-form-item v-if="form.contract == 2">
                                            <el-radio-group v-model="form.savemoney">
                                                <el-radio-button label="1">不托管</el-radio-button>
                                                <el-radio-button label="2">托管</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                        <el-form-item
                                            label="履约保证金金额"
                                            class="ipt-mony"
                                            v-if="form.savemoney == 2 && form.contract == 2"
                                        >
                                            <el-input v-model="form.bail" placeholder="请填写">
                                                <i slot="suffix" class="unit">￥</i>
                                            </el-input>
                                            <span>元</span>
                                        </el-form-item>
                                        <el-form-item v-if="form.contract == 2" label="是否需要分段支付酬金"></el-form-item>
                                        <el-form-item v-if="form.contract == 2">
                                            <el-radio-group
                                                v-model="form.pay"
                                                @change="formPayChange"
                                            >
                                                <el-radio-button label="1">不需要</el-radio-button>
                                                <el-radio-button label="2">需要</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                        <div v-show="form.pay == '2' && form.contract == 2">
                                            <p>
                                                分段支付设置&nbsp;&nbsp;
                                                <!-- <a @click="addSubsData" class="ipt-mony" href="javascript:void(0);"><i class="el-icon-plus"></i> 添加阶段</a> -->
                                            </p>
                                            <div class="subsection-box">
                                                <el-table :data="subsData" style="width: 100%">
                                                    <el-table-column
                                                        prop="numb"
                                                        label="编号"
                                                        width="80"
                                                    ></el-table-column>

                                                    <el-table-column
                                                        prop="name"
                                                        label="阶段名称"
                                                        width="200"
                                                    >
                                                        <template slot-scope="props">
                                                            <el-input
                                                                size="mini"
                                                                placeholder="请输入"
                                                                v-if="props.row.status"
                                                                v-model="props.row.name"
                                                            ></el-input>
                                                            <span v-else>{{props.row.name}}</span>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column
                                                        prop="mony"
                                                        label="金额"
                                                        width="200"
                                                    >
                                                        <template slot-scope="props">
                                                            <el-input
                                                                readonly
                                                                @focus="onSubsDataMoney(props.row.numb)"
                                                                size="mini"
                                                                v-if="props.row.status"
                                                                v-model="props.row.mony"
                                                            >
                                                                <template slot="prepend">￥</template>
                                                                <template slot="append">元</template>
                                                            </el-input>
                                                            <span v-else>{{props.row.mony}}</span>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column
                                                        prop="time"
                                                        label="时间"
                                                        width="250"
                                                    >
                                                        <template slot-scope="props">
                                                            <el-date-picker
                                                                size="mini"
                                                                v-if="props.row.status"
                                                                v-model="props.row.time"
                                                                type="date"
                                                                format="yyyy 年 MM 月 dd 日"
                                                                value-format="yyyy-MM-dd"
                                                                placeholder="选择日期"
                                                                :picker-options="pickerOptions1"
                                                            ></el-date-picker>
                                                            <span v-else>{{props.row.time}}</span>
                                                        </template>
                                                    </el-table-column>

                                                    <el-table-column prop="operation" label="操作">
                                                        <template slot-scope="props">
                                                            <el-button
                                                                style="padding: 10px 10px;"
                                                                v-if="props.row.status"
                                                                @click="subsDataEdit(props.row.numb)"
                                                                size="mini"
                                                            >完成</el-button>
                                                            <el-button
                                                                style="padding: 10px 10px;"
                                                                v-else
                                                                @click="subsDataEdit(props.row.numb)"
                                                                size="mini"
                                                            >编辑</el-button>
                                                            <!-- <el-button style="padding: 10px 10px;" @click="subsDataDelete(props.row.numb)" type="danger" size="mini">删除</el-button> -->
                                                        </template>
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </div>
                                        <el-form-item class="crt-meu">
                                            <el-button type="primary" @click="onSubmit">立即邀请</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ===============================填写分段数量弹出框=================================  -->
                    <el-dialog
                        class="blue-top-border subs-dialog"
                        title="填写分段数量"
                        :visible.sync="handleClickSubs"
                        width="400px"
                        :show-close="false"
                        :close-on-click-modal="false"
                        center
                        @closed="subsformValueDialogClosed"
                    >
                        <el-form ref="subsform" :model="subsform">
                            <el-form-item
                                prop="numb"
                                :rules="[
                          { required: true, message: '分段数量不能为空'},
                          { type: 'number', message: '分段数量必须为数字值'}
                        ]
                      "
                            >
                                <el-input v-model.number="subsform.numb" placeholder="请输入分段数量"></el-input>
                            </el-form-item>
                        </el-form>
                        <span slot="footer" class="dialog-footer news-back-wrap">
                            <el-button @click="handleClickSubs = false;form.pay = 1">取消</el-button>
                            <el-button @click="subsformDialogEnter()">确认</el-button>
                        </span>
                    </el-dialog>
                    <!-- ===============================结束项目弹出框=================================  -->
                    <el-dialog
                        class="red-top-border subs-dialog"
                        title="结束项目"
                        :visible.sync="handleClickEndtask"
                        width="640px"
                        center
                    >
                        <div class="renson-box">
                            <el-form ref="form" :model="Endtaskform">
                                <el-form-item>
                                    <p class="end-txt">
                                        结束项目后，双方已托管的酬金和履约保证金将在
                                        <span class="warn">1个工作日内</span>按原有支付通道返还，请您说明结束项目的原因:
                                    </p>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                        type="textarea"
                                        class="txtare"
                                        v-model="Endtaskform.reason"
                                    ></el-input>
                                </el-form-item>
                                <p class="end-txt warn">项目一旦结束将无法恢复，是否确定结束项目。</p>
                            </el-form>
                        </div>
                        <span slot="footer" class="dialog-footer news-back-wrap">
                            <el-button @click="endTaskEvent()">结束</el-button>
                        </span>
                    </el-dialog>
                    <!-- ===============================身份未认证弹出框=================================  -->
                    <el-dialog
                        class="red-top-border subs-dialog"
                        title="结束项目"
                        :visible.sync="handleClickNotProvince"
                        width="400px"
                        center
                    >
                        <div class="notprov-box">
                            <p class="end-txt">
                                完成
                                <span class="warn">身份认证</span>后才能选择工程极客，请前往完成
                                <span class="warn">身份认证</span>
                            </p>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="centerDialogVisible = false" class="no-menu">取 消</el-button>
                            <el-button type="primary" @click="centerDialogVisible = false">去认证</el-button>
                        </span>
                    </el-dialog>
                    <!-- ===============================设置金额弹出框=================================  -->
                    <el-dialog
                        class="blue-top-border subs-dialog"
                        title="设置金额"
                        :visible.sync="handleClickSetmoney"
                        width="640px"
                        :show-close="false"
                        :close-on-click-modal="false"
                        center
                    >
                        <div class="notprov-box">
                            <ul class="hosting-list">
                                <li class="hosting-item apl-item">
                                    <span>总支付金额：</span>
                                    <i class="col-3">￥{{allMoney}}元</i>
                                </li>
                                <li class="hosting-item">
                                    <span>剩余可分配：</span>
                                    <i class="warn">￥{{remainingMoney}}元</i>
                                </li>
                                <li class="hosting-item">
                                    <div class="slid-box">
                                        <div class="slid-mony-box" style="margin:15px auto">
                                            <div class="slid-mony">
                                                <span>￥</span>
                                                <p>
                                                    <el-input
                                                        style="border: none;text-align: center;font-size: 30px;color: #333333;"
                                                        type="age"
                                                        v-model.number="newMoney"
                                                        autocomplete="off"
                                                    ></el-input>
                                                </p>
                                                <i>元</i>
                                            </div>
                                        </div>
                                        <!-- <el-slider
                              v-model="moneyValue"
                              :show-input-controls='false'
                              :max="moneyMaxValue"
                              @change="moneyValueChange"
                             >
                                        </el-slider>-->
                                        <vue-slider
                                            ref="slider"
                                            :tooltip="'none'"
                                            v-model="moneyValue"
                                            height="15px"
                                            :dot-options="dotOptions"
                                            :enable-cross="false"
                                            :max="moneyMaxValue"
                                            @dragging="dragend"
                                            @change="changes"
                                            :process="sliderStyle"
                                        >
                                            <template v-slot:dot="{ index }">
                                                <div v-if="index === 0" class="dotOneStyle"></div>
                                                <div v-if="index === 1" class="dotTwoStyle"></div>
                                                <div v-if="index === 2" class="dotThreeStyle"></div>
                                                <div v-if="index === 3" class="dotFourStyle"></div>
                                            </template>
                                        </vue-slider>
                                    </div>
                                    <div class="scale-box">
                                        <ul class="scale-list">
                                            <li class="w-10">
                                                <span>10%</span>
                                            </li>
                                            <li class="w-20">
                                                <span>30%</span>
                                            </li>
                                            <li class="w-20">
                                                <span>50%</span>
                                            </li>
                                            <li class="w-20">
                                                <span>70%</span>
                                            </li>
                                            <li class="w-20">
                                                <span>90%</span>
                                            </li>
                                            <li class="w-10 last">&nbsp;</li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="hosting-item">
                                    <p class="tip-wrap warn">每阶段最低可设置金额为总金额的10%</p>
                                </li>
                            </ul>
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="handleClickSetmoney = false">确定</el-button>
                        </span>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
export default {
    name: "remunerationStatue",
    components: {
        VueSlider
    },
    data() {
        return {
            handleClickSubs: false, //填写分段数量
            handleClickEndtask: false, //结束项目弹出框
            handleClickNotProvince: false, //身份未认证弹出框
            handleClickNottrusteeship: false, //托管弹出框
            handleClickSetmoney: false, //设置金额弹出框
            invitationStatus: true, //邀请状态
            trusteeshipStatus: 1, //托管的状态  1未托管 2为托管 3已超时
            paymentStatus: 3, // 1不需要托管 2已托管 3未托管 4已超时
            showRentPrise: false,
            handleClickTopay: true,
            start_placeholder: "开始日期",
            end_placeholder: "结束日期",
            totalNum: 25,
            radio: 1,
            bankchek: 1,
            allMoney: 0,
            formMoney: 0,
            remainingMoney: 0,
            newMoney: 0,
            moneyMaxValue: 0,
            moneyScale: 0,
            moneyValue: [0, 0, 0, 0],
            taskInfo: [],
            avatar: "",
            Endtaskform: {
                reason: ""
            },
            subsform: {
                numb: 2
            },
            subsData: [],
            subsDataIndex: 0,
            form: {
                data: "",
                pay: "1",
                allmoney: 0,
                bail: 0,
                savemoney: "1",
                contract: "1",
                task_day: 0,
                day_money: 0,
                work_time: ""
            },
            formRules: {
                data: [
                    {
                        required: true,
                        message: "请选择成果最终交付时间",
                        trigger: "change"
                    }
                ]
            },
            dotOptions: [
                {
                    disabled: true
                },
                {
                    disabled: true
                },
                {
                    disabled: true
                },
                {
                    disabled: false
                }
            ],
            sliderStyle: dotsPos => [],
            task_id: 0,
            geek_id: 0,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now();
                }
            },
            pickerOptions1: {
                disabledDate(time) {
                    let nowTime = Date.now() + 2 * 24 * 60 * 60 * 1000;
                    return time.getTime() < nowTime;
                }
            }
        };
    },
    methods: {
        send(mid) {
            this.$router.push("/user/message/sendmsg?user_id=" + mid);
        },
        changePayMoney() {
            if (this.taskInfo.task_type == 3) {
                this.form.allmoney = this.form.task_day * this.form.day_money;
            }
            if (this.taskInfo.task_type && this.taskInfo.task_type == 2) {
                this.form.allmoney = this.taskInfo._enroll.quoted_price;
            }
            if (this.getFormMoney() != this.form.allmoney) {
                this.form.pay = "1";
                this.subsData = [];
                this.subsDataIndex = 0;
            }
        },
        teskInfo(task_id, geek_id) {
            var _this = this;
            this.post(
                "/task/task/last",
                { task_id: task_id, geek_id: geek_id },
                data => {
                    if (data.code != 200) {
                        this.$message.error(data.msg);
                        return;
                    }
                    _this.taskInfo = data.data;
                    if (_this.taskInfo.is_bond == 1) _this.form.savemoney = "2";
                    if (_this.taskInfo.is_bond == 1)
                        _this.form.bail = _this.taskInfo.bond;
                    _this.form.work_time = _this.taskInfo.work_time;
                    _this.start_placeholder = data.data.work_time_info[0];
                    _this.end_placeholder = data.data.work_time_info[1];
                    _this.avatar = _this.taskInfo._geek.avatar
                        ? _this.taskInfo._geek.avatar
                        : "";
                }
            );
        },
        formPayChange(val) {
            if (val == 2) {
                if (this.taskInfo.task_type && this.taskInfo.task_type == 2) {
                    this.form.allmoney = this.taskInfo._enroll.quoted_price;
                }
                if (this.taskInfo.task_type && this.taskInfo.task_type == 3) {
                    this.form.allmoney =
                        this.form.task_day * this.form.day_money;
                }
                if (this.form.allmoney <= 0) {
                    this.form.pay = "1";
                    this.$message.error("请先完善项目酬金总额！");
                    return false;
                }
                if (!this.form.data) {
                    this.form.pay = "1";
                    this.$message.error("请先确认成果最终交付时间！");
                    return false;
                }
                this.handleClickSubs = true;
            } else {
                this.subsData = [];
                this.subsform.numb = 0;
            }
        },
        subsformValueDialogClosed() {
            if (this.subsform.numb <= 0) {
                this.form.pay = "1";
                this.subsform.numb = 0;
            }
        },
        subsformDialogEnter() {
            this.$refs["subsform"].validate(valid => {
                if (valid) {
                    if (this.subsform.numb > 5) {
                        this.$message.error("最多可以设置五个阶段！");
                        return false;
                    }
                    if (this.subsform.numb < 2) {
                        this.$message.error("最少需要设置2个阶段！");
                        return false;
                    }
                    this.handleClickSubs = false;
                    if (this.subsform.numb > 1) {
                        this.initSubsData();
                    }
                }
            });
        },
        initSubsData() {
            for (let index = 0; index < this.subsform.numb; index++) {
                this.subsData.push({
                    numb: index + 1,
                    name: "",
                    mony: "",
                    time: "",
                    status: 1
                });
            }
        },
        addSubsData() {
            if (this.subsData.length > 4) {
                this.$message.error("最多可以设置五个阶段！");
                return false;
            }

            this.subsData.push({
                numb: this.subsData.length + 1,
                name: "",
                mony: "",
                time: "",
                status: 1
            });
        },
        subsDataEdit(item) {
            let index = item - 1;
            if (
                this.subsData[index].name.length <= 0 ||
                this.subsData[index].mony.length <= 0 ||
                this.subsData[index].time.length <= 0
            ) {
                this.$message.error("请完善信息！");
                return false;
            }

            if (index != 0) {
                if (
                    this.subsData[index - 1].name.length <= 0 ||
                    this.subsData[index - 1].mony.length <= 0 ||
                    this.subsData[index - 1].time.length <= 0
                ) {
                    this.$message.error("请先完善上一阶段信息！");
                    return false;
                }
                if (this.subsData[index].time < this.subsData[index - 1].time) {
                    this.$message.error(
                        "当前阶段支付时间不能小于上一阶段支付时间！"
                    );
                    return false;
                }
            }
            if (index == this.subsData.length - 1) {
                if (this.subsData[index].time < this.form.data) {
                    this.$message.error(
                        "最后一阶段支付时间不能小于成果交付时间！"
                    );
                    return false;
                }
            }
            this.subsData[index].status =
                this.subsData[index].status == 0 ? 1 : 0;
        },
        subsDataDelete(item) {
            let index = item - 1;
            this.subsData.splice(index, 1);
            let tmp = this.subsData;
            this.subsData = [];
            tmp.forEach((v, k) => {
                this.subsData.push({
                    numb: k + 1,
                    name: v.name,
                    mony: v.mony,
                    time: v.time,
                    status: v.status
                });
            });
        },
        onSubsDataMoney(item) {
            if (this.form.allmoney <= 0) {
                this.$message.error("请先填写项目酬金总额！");
                return false;
            }
            let index = item - 1;
            if (index != 0 && this.subsData[index - 1].status != 0) {
                this.$message.error("请先上一阶段支付信息！");
                return false;
            }
            this.subsData[index].mony = 0;
            this.subsDataIndex = index; // 当前选中条目索引
            this.handleClickSetmoney = true; // 弹窗显示
            this.allMoney = Math.round(this.form.allmoney); // 总金额

            this.moneyMaxValue = this.allMoney; // 滑块最大范围
            this.remainingMoney =
                Math.round(this.form.allmoney) - this.getFormMoney(index); // 剩余金额

            // this.moneyScale = Math.round(this.allMoney * (1 / (this.subsData.length * 2))); // 金额比例 可能要变
            if (index == this.subsData.length) {
                this.moneyScale = Math.round(this.allMoney * (1 / 100)); // 金额比例 可能要变
            } else {
                this.moneyScale = Math.round(this.allMoney * (1 / 10)); // 金额比例 可能要变
            }

            this.moneyValue = this.getSliderValue(index); // 滑块各个点的值
            if (this.remainingMoney == this.form.allmoney) {
                this.newMoney = this.moneyValue[3];
                this.subsData[this.subsDataIndex].mony = this.moneyValue[3];
                // 滑块样式
                this.sliderStyle = dotsPos => [
                    [
                        dotsPos[0],
                        dotsPos[1],
                        {
                            backgroundColor: "#999999",
                            borderRadius: "15px 0px 0px 15px"
                        }
                    ],
                    [
                        dotsPos[1],
                        dotsPos[2],
                        {
                            backgroundColor: "#FA6429",
                            borderRadius: "15px 0px 0px 15px"
                        }
                    ],
                    [
                        dotsPos[2],
                        dotsPos[3],
                        {
                            backgroundColor: "#74A5F7",
                            borderRadius: "0px"
                        }
                    ],
                    [
                        dotsPos[3],
                        dotsPos[4],
                        {
                            backgroundColor: "#999999",
                            borderRadius: "15px 0px 0px 15px"
                        }
                    ]
                ];
            } else {
                this.newMoney = this.moneyScale;
                this.subsData[this.subsDataIndex].mony = this.moneyScale;
                if (index == this.subsData.length - 1) {
                    this.newMoney = this.remainingMoney;
                    this.subsData[
                        this.subsDataIndex
                    ].mony = this.remainingMoney;
                }
                this.sliderStyle = dotsPos => [
                    [
                        dotsPos[0],
                        dotsPos[1],
                        {
                            backgroundColor: "#999999",
                            borderRadius: "15px 0px 0px 15px"
                        }
                    ],
                    [
                        dotsPos[1],
                        dotsPos[2],
                        {
                            backgroundColor: "#FA6429",
                            borderRadius: "0px"
                        }
                    ],
                    [
                        dotsPos[2],
                        dotsPos[3],
                        {
                            backgroundColor: "#74A5F7",
                            borderRadius: "0px"
                        }
                    ],
                    [
                        dotsPos[3],
                        dotsPos[4],
                        {
                            backgroundColor: "#999999",
                            borderRadius: "15px 0px 0px 15px"
                        }
                    ]
                ];
            }
        },
        getFormMoney(index = "") {
            let formMoney = 0;
            if (index) {
                this.subsData.forEach(v => {
                    if (index + 1 != v.numb) formMoney = formMoney + v.mony;
                });
            } else {
                this.subsData.forEach(v => {
                    formMoney = formMoney + v.mony;
                });
            }
            return Math.round(formMoney);
        },
        getSliderValue(index) {
            if (index || index == 0) {
                localStorage.setItem("invite_index", index);
            } else {
                index = localStorage.getItem("invite_index");
            }
            let has_set = 0;
            for (let index1 = 0; index1 < this.subsData.length; index1++) {
                if (this.subsData[index1].mony > 0 && index != index1) {
                    has_set += 1;
                }
            }
            let cutMoney = 0; //需要扣减的不可用金额
            let not_set = 0;
            let test = 0;
            not_set = this.subsData.length - has_set;
            if (index == 0) {
                cutMoney = Math.round(
                    this.allMoney *
                    (1 - 0.01 - 0.1 - (this.subsData.length - 2) * 0.1)
                );
                test = 0;
            }
            if (index > 0 && index < this.subsData.length - 1) {
                // 2、3、4段
                if (this.subsData[index].mony > 0) {
                    cutMoney =
                        Math.round(
                            this.allMoney *
                            (1 -
                                0.1 -
                                0.01 -
                                (this.subsData.length - has_set) * 0.1)
                        ) +
                        (this.subsData[index].mony - this.moneyScale);
                    test =
                        this.getFormMoney() -
                        this.allMoney * ((has_set - 1) * 0.1 - 0.01);
                } else {
                    cutMoney = Math.round(
                        this.allMoney *
                        (1 -
                            0.1 -
                            0.01 -
                            (this.subsData.length - has_set) * 0.1)
                    );
                    test =
                        this.getFormMoney() -
                        this.allMoney * ((has_set - 1) * 0.1 - 0.01);
                }
            }
            if (
                index == this.subsData.length - 1 ||
                (index == this.subsData.length - 2 &&
                    this.subsData[index].mony > 0)
            ) {
                cutMoney = 0;
            }
            cutMoney = cutMoney - test;
            if (cutMoney < 0) cutMoney = 0;
            // if (has_set == this.subsData.length - 2){
            //   cutMoney = 0;
            // }else{
            //   cutMoney = Math.round(this.allMoney * (1 - (this.subsData.length-has_set-1)*0.1)); // 滑块最大范围
            // }
            // if (index + 1 == 1){ // 第一段设置
            //   cutMoney = Math.round(this.allMoney * (1 - 0.01 - (this.subsData.length - 2)));
            // }else if (index + 1 == this.subsData.length){ // 最后一段设置
            //   cutMoney = 0;
            // }else{
            //   if (this.subsData[index].mony > 0){
            //     cutMoney = Math.round(this.allMoney * (1 - 0.01 - (this.subsData.length-has_set-1)*0.1)) - (this.subsData[index].mony - this.moneyScale);
            //   }else{
            //     cutMoney = Math.round(this.allMoney * (1 - (this.subsData.length-has_set-1)*0.1));
            //   }
            // }
            let value1 = this.getFormMoney(index),
                value2 = Math.round(this.getFormMoney(index) + this.moneyScale),
                value3 = Math.round(
                    this.getFormMoney(index) + this.moneyScale + 0.4999999
                ),
                value4 = Math.round(
                    this.getFormMoney(index) +
                    this.moneyScale +
                    cutMoney +
                    0.4999999
                );
            if (index == this.subsData.length - 1) {
                this.dotOptions = [
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    }
                ];
                return [
                    0,
                    this.allMoney,
                    this.allMoney,
                    this.allMoney,
                    this.allMoney
                ];
            } else {
                this.dotOptions = [
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    },
                    {
                        disabled: true
                    },
                    {
                        disabled: false
                    },
                    {
                        disabled: true
                    }
                ];
            }
            return [0, value1, value2, value3, value4];
        },
        dragend(values) {
            if (values[3] <= this.moneyValue[2]) {
                let value = this.getSliderValue();
                this.$refs.slider.blur();
                return false;
            }
        },
        changes() {
            let newMoney = 0;
            if (this.remainingMoney == this.form.allmoney) {
                newMoney = this.moneyValue[3];
            } else {
                newMoney =
                    this.moneyValue[3] - this.moneyValue[2] + this.moneyScale;
            }
            this.newMoney = newMoney;
            this.subsData[this.subsDataIndex].mony = newMoney;
        },
        onSubmit() {
            var _this = this;
            for (let index = 0; index < this.subsData.length; index++) {
                if (this.subsData[index].status == 1) {
                    _this.$message.error(
                        "分段支付设置存在未完成的条目，请先完善分段支付设置！"
                    );
                    return false;
                }
            }
            if (this.taskInfo.task_type && this.taskInfo.task_type == 2) {
                this.form.allmoney = this.taskInfo._enroll.quoted_price;
            }
            if (this.taskInfo.task_type && this.taskInfo.task_type == 3) {
                this.form.allmoney = this.form.task_day * this.form.day_money;
            }
            _this.$refs["formRules"].validate(valid => {
                if (valid) {
                    if (_this.form.allmoney <= 0) {
                        _this.$message.error("请填写项目酬金总额！");
                        return false;
                    }
                    if (
                        _this.getFormMoney() != _this.form.allmoney &&
                        _this.form.pay == 2
                    ) {
                        _this.subsData = [];
                        _this.$message.error(
                            "分段支付金额总和与项目酬金总额不符"
                        );
                        return false;
                    }
                    let form = {
                        task_id: _this.task_id,
                        geeks_id: _this.geek_id,
                        delivery_time: _this.form.data,
                        pay_type: _this.form.pay,
                        remuneration: _this.form.allmoney,
                        pay_info: JSON.stringify(_this.subsData),
                        is_bail: _this.form.savemoney,
                        bail: _this.form.bail,
                        is_contract: _this.form.contract,
                        task_day: _this.form.task_day,
                        work_time: _this.form.work_time
                    };
                    // JSON.parse(s)  字符串转json
                    _this.post("/task/task/invite", form, data => {
                        if (data.code != 200) {
                            _this.$message.error(data.msg);
                            return;
                        }
                        _this.$message.success(
                            data.data === true ? "邀请成功" : "邀请失败"
                        );
                        setTimeout(() => {
                            window.location.href = "/user/todos/list/all";
                        }, 1500);
                    });
                }
            });
        },
        endTaskEvent() {
            var _this = this;
            _this.handleClickEndtask = false;
        },
        goTaskInfo(task_id) {
            this.$router.push("/task/detail?task_id=" + task_id);
        }
    },
    created() { },
    mounted() {
        this.$nextTick(function () {
            var task_id = this.$route.query.tid,
                geek_id = this.$route.query.gid;
            if (task_id && geek_id) {
                this.task_id = task_id;
                this.geek_id = geek_id;
                this.teskInfo(task_id, geek_id);
            }
        });
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/todos.less";
</style>
<style>
.dotOneStyle,
.dotTwoStyle,
.dotThreeStyle {
    width: 0px;
    height: 0px;
}
.dotFourStyle {
    width: 18px;
    height: 23px;
    position: absolute;
    top: -8px;
    background: linear-gradient(-45deg, transparent 5px, #587ddc 0) right,
        linear-gradient(45deg, transparent 5px, #587ddc 0) left !important;
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border: none;
    opacity: 1;
}
.merge-tooltip {
    position: absolute;
    left: 50%;
    bottom: 100%;
    transform: translate(-50%, -15px);
}
.el-radio-button__inner {
    padding: 6px 20px;
}


</style>


